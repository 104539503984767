import React, { ReactNode, useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import { AppContext, IAppContext } from '../../context'
import { imagePath, formatDate } from '../../api'
import './style.scss'

const getLogoOrName = (name: string, graphic: string): string | React.JSX.Element => {
  if (graphic === '') return name
  return (
    <img
      alt={name}
      src={imagePath({
        digest: graphic,
        opts: {
          height: 190,
          width: 1000,
          fit: true,
          format: 'png'
        }
      })}
    />
  )
}

const getTitle = (hasScheduleItem: boolean, location: string, scheduleDesc: string, description: string): string | React.JSX.Element => {
  if (hasScheduleItem) {
    return (
      <>
        {location}
        <br />
        {scheduleDesc}
      </>
    )
  }
  return description
}

const getDateStr = (hasScheduleItem: boolean, startsAt: string, timeStr: string): string | React.JSX.Element => {
  if (hasScheduleItem) {
    const dateStr = formatDate(startsAt, {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    })
    return `${dateStr} ${timeStr}`
  }
  return ''
}

const getTimeStr = (time: string): string => {
  if (time === '') return `at ${time}`
  return ''
}

export default function Header (): ReactNode {
  const { event = {} } = useContext<IAppContext>(AppContext)
  const { locator = '', scheduleItemId = '0' } = useParams()
  const { graphic = '', name = '', description = '', scheduleItems = [] } = event
  const scheduleItem = scheduleItems?.find(
    ({ id }) => id === parseInt(scheduleItemId)
  )
  const { time = '', startsAt = '', location = '', description: scheduleDesc = '' } = scheduleItem ?? {}

  const title = getTitle(scheduleItem != null, location, scheduleDesc, description)
  const logoOrName = getLogoOrName(name, graphic)
  const dateStr = getDateStr(scheduleItem != null, startsAt, getTimeStr(time))

  return (
    <div className='Header'>
      <Link to={`/${locator}`} className='Header__Logo'>
        {logoOrName}
      </Link>

      <h1 className='Header__Name'>{title}</h1>
      <h2 className='Header__Date'>{dateStr}</h2>
    </div>
  )
}
