import React, { ReactEventHandler, ReactNode } from 'react'
import SquareContainer from '../SquareContainer'
import Empty from './empty.png'
import { thumbPath, ImageSize } from '../../../api'
import './style.scss'
import classNames from 'classnames'

interface Props {
  digest: string
  onLoad: ReactEventHandler<HTMLImageElement>
  playable?: boolean
  className?: string
  alt?: string
}

const Thumbnail = ({
  digest,
  onLoad,
  playable = false,
  className = '',
  alt = ''
}: Props): ReactNode => {
  if (digest === '') return <EmptyThumbnail className={className} />

  const isEdge = navigator.userAgent.includes('Edge')
  const options: Record<string, any> = { fit: false }

  if (playable) {
    options.format = 'gif'
    options.animated = true
  }

  const url = thumbPath(digest, ImageSize.thumb, options)
  const classes = classNames(className, 'Thumbnail', 'Thumbnail--image', {
    'Thumbnail--playable': playable
  })

  // MS Edge can not properly render images with style object-fit: cover.
  // Remove this conditional if/when the new version of edge can scale these images
  if (isEdge) {
    return (
      <SquareContainer>
        <div
          className={classes}
          style={{
            backgroundImage: `url(${url})`
          }}
          title={alt}
        />
      </SquareContainer>
    )
  }

  return (
    <SquareContainer>
      <img
        alt={alt}
        className={classes}
        src={url}
        loading='lazy'
        onLoad={onLoad}
      />
    </SquareContainer>
  )
}

interface EmptyProps {
  className: string
}

const EmptyThumbnail = ({ className }: EmptyProps): ReactNode => (
  <SquareContainer>
    <div className={classNames(className, 'Thumbnail Thumbnail--empty')}>
      <img src={Empty} alt='Empty' className='Thumbnail__Icon' loading='lazy' />
    </div>
  </SquareContainer>
)

export default Thumbnail
