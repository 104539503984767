import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppContext, IAppContext, ModalContext, IModalContext } from '../../context'
import { checkPassword } from '../../api'
import classNames from 'classnames'
import Field from '../core/Field'
import { savePassword } from '../../localStorage'
import './style.scss'

export default function PasswordGate (): JSX.Element {
  const { event } = useContext<IAppContext>(AppContext)
  const { open, to, id, showPasswordModal } = useContext<IModalContext>(ModalContext)
  const [password, setPassword] = useState<string>('')
  const [error, setError] = useState(false)
  const navigate = useNavigate()
  const background = React.createRef<HTMLDivElement>()

  const handleChange = ({ target }): void => {
    setError(false)
    setPassword(target.value)
  }

  const handleClick = async (): void => {
    const { locator = '' } = event

    const promiseHandler = async (): Promise<void> => {
      try {
        await checkPassword({
          locator,
          scheduleItemId: id,
          password
        })

        savePassword(id, password)
        showPasswordModal(false, null)
        navigate(to ?? `/${locator}`)
      } catch (e) {
        setError(true)
      }
    }

    void promiseHandler()
  }

  const handleCancel = (e): void => {
    e.preventDefault()
    if (e.target === background.current) showPasswordModal?.(false, null)
  }

  const className = classNames('PasswordGate', { 'PasswordGate--error': error })

  if (open) {
    return (
      <div
        data-testid='password-cancel'
        className={className}
        ref={background}
        onClick={handleCancel}
      >
        <div className='PasswordGate__Modal'>
          <h1 className='PasswordGate__Title'>
            This event is password protected.
          </h1>

          <Field
            error={error}
            name='password'
            className='PasswordGate__Field'
            placeholder='Enter your event password'
            value={password}
            onChange={handleChange}
            role='password-input'
          />

          <div className='PasswordGate__Buttons'>
            <button
              data-testid='password-submit'
              className='primary'
              onClick={handleClick}
            >
              Go
            </button>
          </div>
        </div>
      </div>
    )
  }

  return <></>
}
